<template>
  <div>
    <h2>401 Unauthorized</h2>
    <p>You are not authorized to view this page.</p>
    <router-link to="/login">Login</router-link>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
