<template>
  <div>
    <header>
      <h1>Welcome to the Kids Points System</h1>
      <div v-if="!userName">
        <router-link to="/login">Login</router-link>
      </div>
      <div v-else>
        <span>Welcome, {{ userName }}</span>
        <router-link to="/logout">Logout</router-link>
      </div>
    </header>
    
    <h2>Gifts and Activities</h2>
    <!-- Display gifts and activities here -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const userName = ref('');

onMounted(() => {
  userName.value = getCookie('username'); // Function to retrieve username from cookies
});

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
</script>

<style scoped>
/* Add your styles here */
</style>
