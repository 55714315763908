<template>
  <div>
    <h2>User Information</h2>
    <div v-if="user">
      <p>Name: {{ user.show_name }}</p>
      <p>Points: {{ user.points }}</p>
      <img :src="user.avatar" alt="User Avatar" v-if="user.avatar" />
    </div>
    <div v-else>
      <p>User not found.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const user = ref(null);

onMounted(async () => {
  const username = route.params.username;
  try {
    const response = await axios.get(`/api/user/${username}`);
    user.value = response.data;
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
/* Add your styles here */
</style>
