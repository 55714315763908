<template>
  <div>
    <h2>Logout</h2>
    <p>You have been logged out.</p>
    <router-link to="/main">Go to Main Page</router-link>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
});
</script>

<style scoped>
/* Add your styles here */
</style>
