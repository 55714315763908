<template>
  <div id="app">
    <header>
      <nav>
        <router-link to="/main">Home</router-link>
        <router-link to="/login">Login</router-link>
        <router-link to="/logout">Logout</router-link>
      </nav>
    </header>
    <main>
      <router-view />
    </main>
    <footer>
      <p>&copy; 2024 Kids Points System</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// Example: Handling user authentication state
const isAuthenticated = ref(false);

// Example function to check authentication status
const checkAuthentication = () => {
  const username = getCookie('username');
  isAuthenticated.value = !!username; // Set authenticated state based on cookie presence
};

// Function to retrieve a cookie value
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Check authentication status when the app is mounted
onMounted(() => {
  checkAuthentication();
});
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

header {
  background-color: #f8f9fa;
  padding: 20px;
}

nav {
  display: flex;
  justify-content: center;
  gap: 15px;
}

nav a {
  text-decoration: none;
  color: #35495e;
}

nav a:hover {
  text-decoration: underline;
}

footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
}
</style>
