<template>
  <div class="login-container">
    <form @submit.prevent="login">
      <h2>Login</h2>
      <input type="text" v-model="username" placeholder="Username" required />
      <input type="password" v-model="password" placeholder="Password" required />
      
      <div class="captcha-container">
        <input type="text" v-model="captcha" placeholder="Captcha" required />
        <img :src="captchaImage" alt="Captcha" class="captcha-image" @click="refreshCaptcha" />
      </div>
      
      <div class="button-container">
        <button type="submit">Submit</button>
        <button type="button" @click="reset">Reset</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const username = ref('');
const password = ref('');
const captcha = ref('');
const captchaImage = ref('');
const captchaTimestamp = ref(null);
const captchaExpiry = 60 * 1000; // 1 minute in milliseconds

const fetchCaptcha = async () => {
  try {
    const response = await axios.get('/api/captcha');
    const base64Image = response.data.image; // Get the base64 image string
    captchaImage.value = `data:image/png;base64,${base64Image}`;
    captchaTimestamp.value = Date.now(); // Store the current timestamp
  } catch (error) {
    console.error('Error fetching captcha:', error);
  }
};

const refreshCaptcha = () => {
  if (!captchaTimestamp.value || Date.now() - captchaTimestamp.value > captchaExpiry) {
    fetchCaptcha();
  }
};

onMounted(() => {
  fetchCaptcha();
});

const login = async () => {
  try {
    const response = await axios.post('/api/user', {
      username: username.value,
      password: password.value,
      captcha: captcha.value,
    });
    console.log(response.data);
    window.location.href = '/';
  } catch (error) {
    alert(error.response.data.message);
  }
};

const reset = () => {
  username.value = '';
  password.value = '';
  captcha.value = '';
  fetchCaptcha(); // Refresh captcha
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

input {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.captcha-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.captcha-image {
  width: 40px;    /* Set the width to 40 pixels */
  height: 15px;   /* Set the height to 15 pixels */
  margin-left: 10px; /* Add some space between the input and image */
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Space buttons evenly */
  margin-top: 10px; /* Add some spacing above buttons */
}

button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  width: 48%; /* Set width to evenly split the space */
}

button:hover {
  background-color: #45a049;
}
</style>
