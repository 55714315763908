<template>
  <div>
    <h2>500 Internal Server Error</h2>
    <p>There was an error processing your request.</p>
    <router-link to="/main">Go to Main Page</router-link>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
