<template>
  <div>
    <h2>404 Not Found</h2>
    <p>The page you are looking for does not exist.</p>
    <router-link to="/main">Go to Main Page</router-link>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
